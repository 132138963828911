<template>
  <div>
    <Header :seet="number" />
      <el-row type="flex" class="account—settings">
        <el-col :span="3" style="width:15.3%;"></el-col>
        <el-col :span="17">
          <div class="settings_box">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>账号中心</el-breadcrumb-item>
              <el-breadcrumb-item @click.native="goto()">退换货</el-breadcrumb-item>
              <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
            </el-breadcrumb>
            <div style="display:flex;">
              <div class="box_left">
                <LeftHurdle/>
              </div>
              <div class="box_rights">
                <div class="box_right_top">{{title}}</div>
                <div class="box_right_cent" v-for="(item,index) in list" :key="index">
                    <div class="apply_box">
                        <div class="apply_list">
                            <div class="flex-start list-line">
                                <div class="list-text">订单号</div>
                                <div class="list-number list-width">{{item.orderNo}}</div>
                            </div>
                            <div class="flex-start list-line">
                                <div class="list-text">订单商品</div>
                                <div class="list-width">{{item.goodsName}}    <span style="padding:0 10px;">{{item.specValues}}</span>       {{item.number}}件</div>
                            </div>
                            <div class="flex-start list-line">
                                <div class="list-text">交易金额</div>
                                <div class="list-width">{{(item.number * item.goodsPrice).toFixed(2)}}</div>
                            </div>
                            <div class="flex-start list-line">
                                <div class="list-text">购买时间</div>
                                <div class="list-width">{{item.createTime}}</div>
                            </div>
                            <div class="flex-start list-line">
                                <div class="list-text">收货地址</div>
                                <div class="list-width">{{item.address}}，{{item.customerName}}，{{item.phoneNo}}</div>
                            </div>
                        </div>  
                        <div class="apply_barter">
                            <div class="flex-start">
                              <div v-for="(item2,index) in listdata" :key="index" @click="changeAct(item2.value)" >
                                <div :class="[act === item2.value ? 'barter_box' : 'barter_Box']">
                                  <div class="order-name">
                                      {{item2.label}}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="barter_cent">
                                <div class="flex-start">
                                    <div>申请售后数量：</div>
                                    <el-input-number v-model="num" @change="handleChange" :min="item.number == 0 ? 0 : 1" :max="item.number" label="描述文字"></el-input-number>
                                </div>
                                  <div class="flex-start" style="margin-top: 15px;" v-show="act!=5 && act!=6">
                                      <div>申请退款金额：</div>
                                      <el-input
                                        v-model="Price"
                                        oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                                        placeholder="请输入内容"
                                        class="barter-input"
                                      ></el-input>
                                      <span style="color:red;font-size:34px">*</span>
                                      <div style="padding-left:10px;">本次最多可退{{priceChange(Price)}}元</div>
                                  </div>
                                <!-- <div class="barter-goods" v-show="act==5 && demo!=1">
                                  <div class="barter">换货商品</div>
                                  <div class="flex-start">
                                    <div>换货颜色：</div>
                                    <div class="flex-start">
                                      <div class="barter-color"></div>
                                      <div class="barter-color"></div>
                                    </div>
                                  </div>
                                </div> -->
                                <div class="flex-start" style="padding-top:15px;">
                                    <div>申请售后原因：</div>
                                     <el-select v-model="value" placeholder="请选择">
                                        <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                        </el-option>
                                    </el-select>
                                    <span style="color:red;font-size:34px">*</span>
                                </div>
                                <div class="barter_list">
                                    <div>申请售后说明：</div>
                                    <div>
                                        <el-input
                                            type="textarea"
                                            :rows="4"
                                            placeholder="请输入内容"
                                            v-model="textarea" class="barter_textarea">
                                        </el-input>
                                        <div class="up_img">
                                            <el-upload
                                              action="/api/admin/bulk-purchase-invoice-config/uploadImaage"
                                              list-type="picture-card"
                                              :limit="5"
                                              :on-preview="handlePictureCardPreview"
                                              :on-success="handleSuccess"
                                              :on-remove="handleRemove"
                                              :headers="headers"
                                              :file-list="fileList">
                                              <el-button size="small" type="primary">点击上传</el-button>
                                            </el-upload>
                                            <el-dialog :visible.sync="dialogVisible">
                                              <img width="100%" :src="dialogImageUrl" alt="">
                                            </el-dialog>
                                            <div style="font-size:14px;color:red;">每张图片的大小不超过2M，最多5张，支持GIF、JPG、PNG、BMP、JPEG格式</div>
                                            <div class="flex-start" style="margin-top:15px;">
                                                <el-button class="up_btn" @click="applyforsales()" :disabled="btnChangeEnable" :loading="submitLoad">提交</el-button>
                                                <el-button class="up_btn" @click="goback()">取消</el-button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
              </div>
            </div>
            
          </div>
        </el-col>
        <el-col :span="3" style="width:15.5%;"></el-col>
    </el-row>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Layout/header.vue'
import Footer from '@/components/Layout/footer.vue'
import LeftHurdle from '@/components/Layout/leftHurdle.vue'
import { 
  // returnspecificat, 
  applyforsales,
  afterAttribute,
  updateAfterSaleApply
} from "@/api/order/aftersale"
export default {
  components: { Header,Footer,LeftHurdle },
  data () {
      return{
        number:'5',
        num: 1,
        input:'',
        list:[],
        currentPage:1,
        textarea:'',
        ruleForm: {
          value: '',
          Price:''

        },
        rules: {
          value: [
            { required: true, message: '请选择售后原因', trigger: 'blur' },
          ],
          Price: [
            { required: true, message: '请输入价格', trigger: 'blur' },
          ],
        },
        listdata: [
        {value:2,label:'退货退款'},
            // {value:5,label:'换货'},
            // {value:6,label:'返修'},
            ],
        act:2,
        options: [{
          value: '商品损坏/包装脏污',
          label: '商品损坏/包装脏污'
        }, {
          value: '商品故障',
          label: '商品故障'
        }, {
          value: '少/错商品',
          label: '少/错商品'
        }, {
          value: '发错货',
          label: '发错货'
        }, {
          value: '商品与页面描述不符',
          label: '商品与页面描述不符'
        }, {
          value: '质量问题',
          label: '质量问题'
        }, {
          value: '其他',
          label: '其他'
        }],
        value: '',
        demo:1,
        goodsPrice:0,
        id:0,
        orderGoodsId:0,
        dialogImageUrl: '',
        dialogVisible: false,
        disabled: false,
        fileList:[],
        Price:0,
        title:'申请售后',
        afterSaleInfoId:'',
        payTime: null,
        headers: {Authorization: localStorage.getItem('token') },
        btnChangeEnable:false,
        submitLoad:false,
      }
  },
  created(){
    
    if(this.$route.query.index !==undefined){// 退换货 进来的申请
      let data = JSON.parse(this.$route.query.index)
      console.log(data);
      data.forEach(item => {
        // 订单关联类型orderAssociationType 处理京东商品
        if(item.orderAssociationType==2){
          this.JDgoods(item.orderGoodsId)
        } else {
          // 本地订单 
          this.localgoods(item.state,'',item.orderAssociationType)
        }
      })
      this.list=data
      data.forEach(item => {
        this.id=item.goodsId
        this.goodsPrice=item.goodsPrice
        this.orderGoodsId=item.orderGoodsId
        this.payTime=item.payTime
        
        this.Price = item.payTime == null ? 0 : item.goodsPrice;
        
      });
    }else if(this.$route.query.list!==undefined){ // 重新申请
      let name =JSON.parse(this.$route.query.name)
      let list =JSON.parse(this.$route.query.list)
      // console.log(name,list);
      let obj={}
      obj.orderNo=name.orderNo
      obj.goodsName=list.goodsInfos[0].goodsName
      obj.specValues=list.goodsInfos[0].specValues
      obj.number=list.goodsInfos[0].refundNum
      obj.goodsPrice=list.goodsInfos[0].refundMoney
      obj.createTime=name.createTime
      obj.goodsPrice=list.goodsInfos[0].refundMoney
      obj.address=list.address
      obj.customerName=list.customerName
      obj.phoneNo=list.phoneNo
      this.list.push(obj)
      this.orderGoodsId=list.goodsInfos[0].orderGoodsId
      this.num=list.goodsInfos[0].refundNum	
      this.id=list.goodsInfos[0].goodsId
      this.goodsPrice=list.goodsInfos[0].refundMoney
      this.afterSaleInfoId=list.goodsInfos[0].id
      this.Price= list.payTime == null ? 0 : list.goodsInfos[0].refundMoney * this.num
      this.payTime= list.payTime
      if(list.goodsInfos[0].sourceType==1){// 京东商品
        if(!list.jdResponse){
          this.$message({
            type: 'error',
            message: '获取售后信息异常，请联系管理员'
          });
        }
        this.JDgoods(list.goodsInfos[0].orderGoodsId)
      } else {
        // 本地订单 
        this.localgoods('',list.type,list.orderResource)
      }
      // console.log("重新申请",this.demo);
    }else{ // 修改售后申请
      let name =JSON.parse(this.$route.query.name)
      console.log("xgshsq", name);
      let obj={}
      obj.orderNo=name.orderNo
      obj.goodsName=name.goodsInfos[0].goodsName
      obj.specValues=name.goodsInfos[0].specValues
      obj.number=name.goodsInfos[0].refundNum
      obj.goodsPrice=name.goodsInfos[0].refundMoney
      obj.createTime=name.createTime
      obj.goodsPrice=name.goodsInfos[0].refundMoney
      obj.address=name.address
      obj.customerName=name.customerName
      obj.phoneNo=name.phoneNol
      this.list.push(obj)
      this.num = name.goodsInfos[0].refundNum	
      this.Price= name.payTime == null ? 0 : name.goodsInfos[0].refundMoney * this.num
      this.payTime= name.payTime
      this.id=name.goodsInfos[0].goodsId
      this.goodsPrice=name.goodsInfos[0].refundMoney
      this.orderGoodsId=name.goodsInfos[0].orderGoodsId
      this.afterSaleInfoId=name.id
      this.title='修改售后'
      this.demo=2
      if(name.reason!=''){
        this.options.forEach(item=>{
          if(item.label==name.reason){
            this.value= item.value
          }
        })
      }
      if(name.description!=''){
        this.textarea= name.description
      }
      if(name.pic !=''){
        if (name.pic) {
            let arr = name.pic.split(",");
            if(arr.length==0){
              this.fileList =[]
            }else{
              arr.forEach((item,index)=>{
                if(item!=''){
                  let temp = {
                      url: item,
                  };
                  this.fileList[index]=temp;
                }
              })
            }
        }
      }
      if(name.goodsInfos[0].sourceType==1){// 京东商品
        this.JDgoods(name.goodsInfos[0].orderGoodsId)
      } else {
        // 本地订单 
        this.localgoods('',name.type,name.orderResource)
      }

    }
    
  },
  methods:{
      localgoods(state,type,from = null){// 本地订单 type是重新申请/修改申请用的
      // console.log(state,type,from);
        if(state == 2){
          this.listdata = [
            {value:1,label:'退款'}
          ]
        } else if(state == 3 || state == 5){
          if(from != 4){// 震坤行商品不能换货
            this.listdata = [
              {value:1,label:'退款'},
              {value:2,label:'退货退款'},
              {value:5,label:'换货'},
              // {value:6,label:'返修'},
            ]
          } else {
            this.listdata = [
              {value:1,label:'退款'},
              {value:2,label:'退货退款'},
            ]
          }
        } else if(type == 1){
          this.listdata = [
            {value:1,label:'退款'}
          ]
        } else if(type == 2 || type == 5 || type == 6){
          if(from != 4){// 震坤行商品不能换货
            this.listdata = [
              {value:1,label:'退款'},
              {value:2,label:'退货退款'},
              {value:5,label:'换货'},
              // {value:6,label:'返修'},
            ]
          } else {
            this.listdata = [
              {value:1,label:'退款'},
              {value:2,label:'退货退款'},
            ]
          }
        }
        this.act=this.listdata[0].value
      },
      JDgoods(Id){// 京东
        afterAttribute(Id).then(res=>{
          if(res&&res.code=="00000"){
            res.data.result.forEach(item=>{
              if(item.wareNum==0){
                this.$message({
                  type: 'error',
                  message: '此商品不可售后,请联系客服！'
                });
                this.$router.push({ name: "ChangingRefund"});
              }else{
                //商品支持的服务类型customerExpect
                let customerExpect=item.customerExpect
                if(customerExpect.length==0){
                  this.$message({
                    type: 'error',
                    message: '此商品不可售后！'
                  });
                  this.btnChangeEnable=true
                }else{
                  this.listdata=[]
                  customerExpect.find(itm=>{
                    if(itm==10){
                      this.listdata.push({value:2,label:'退货退款'})
                    }else if(itm==20){
                      this.listdata.push({value:5,label:'换货'})
                    }else if(itm==30){
                      this.listdata.push({value:6,label:'返修'})
                    }
                  })
                  this.act=this.listdata[0].value
                }
              }
            })
          }
        })

      },
      addTicket(){
        this.$router.push({ name: 'AddTicket' })
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
      },
      handleChange(value) {
        if(this.payTime == null) {
          this.Price = 0
        } else {
          this.Price = (value * this.goodsPrice).toFixed(2)
        }
        
      },
      //切换
      changeAct(val){
          this.act = val
      },
      // 退款金额显示
      priceChange(e){
        // console.log(e,this.num, this.goodsPrice);
        if(!this.payTime){
          return 0
        } else if(e == 0 || e == ''){
          return 0
        } else if(parseFloat(e) > +(this.num * this.goodsPrice).toFixed(2)){
          return +(this.num * this.goodsPrice).toFixed(2)
        } else {
          return parseFloat(e).toFixed(2)
        }
      },
      
      handleSuccess(file, fileList){
        if (file.status == "success"  || file.code == "00000") {
          let obj={}
          obj.uid=fileList.uid
          obj.url=file.data
          this.fileList.push(obj)
        } else {
          this.$message.error(file.desc);
          this.handleRemove();
        }
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      handleRemove(file) {
        this.fileList.forEach(item=>{
          if(file.uid==item.uid){
            this.fileList.splice(item,1)
          }
        })
      },
      applyforsales(){
        let img="";
        // let imgAry=[]
        if(this.fileList.length==0){
          img=''
        }else{
          this.fileList.forEach((item)=>{
            // img=item.url+','
            img += item.url+","
          })
        }

        if(this.act == 1 || this.act == 2){
          // console.log(isNaN(this.Price),parseFloat(this.Price),this.payTime);
          if(this.Price==''&& this.payTime!=null){
            this.$message({
              type: 'error',
              message: '请输入申请退款金额!'
            });
            return
          }else if(parseFloat(this.Price) > (this.num * this.goodsPrice).toFixed(2)){
            this.$message({
              type: 'error',
              message: '退款金额不可以大于交易金额!'
            });
            return
          } else if(isNaN(this.Price)){
            this.$message({
              type: 'error',
              message: '退款金额请输入数字!'
            });
            return
          }
        }
        if(this.value==''){
          this.$message({
            type: 'error',
            message: '请选择申请售后原因!'
          });
          return
        }
        this.submitLoad = true
        this.$confirm('确认申请售后', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          
          if(this.demo==2){
            let data={
              "afterSaleInfoId": this.afterSaleInfoId,
              "image": img,
              "orderGoodsId": this.orderGoodsId,
              "refundDescription": this.textarea,
              "refundNums": this.num,
              "refundPrice": parseFloat(this.Price).toFixed(2),
              "refundReason": this.value,
              "refundType": this.act
            }
            updateAfterSaleApply(data).then((data)=>{
              if(data.code=="00000"){
                this.$message({
                  type: 'success',
                  message: '修改成功!'
                });
                this.$router.go(-1);
              }else{
                this.$message({
                  type: 'error',
                  message: data.desc
                });
                this.submitLoad = false
              }
            }).catch(()=>{this.submitLoad = false})
          }else{
            let data={
              "image": img,
              "orderGoodsId": this.orderGoodsId,
              "refundDescription": this.textarea,
              "refundNums": this.num,
              "refundPrice": parseFloat(this.Price).toFixed(2),
              "refundReason": this.value,
              "refundType": this.act
            }
            applyforsales(data).then((data)=>{
              if(data.code=="00000"){
                this.$message({
                  type: 'success',
                  message: '申请成功!'
                });
                this.$router.push({ name: "ChangingRefund"});
              }else{
                this.$message({
                  type: 'error',
                  message: data.desc
                });
                this.submitLoad = false
              }
            }).catch(()=>{this.submitLoad = false})
          }
            
        }).catch(() => {
          this.submitLoad = false
        });
        
      },
      goback(){
        this.$router.go(-1);
      },
      goto(){
        this.$router.push({ name: 'ChangingRefund'})
      },
      

  }

}
</script>

<style lang="scss">
.settings_box{
  margin: 30px 0;
  .el-breadcrumb{
    font-size: 18px;
    padding-bottom: 20px;
  }
  .box_left{
    margin-right: 20px;
  }
  .box_rights{
    width: 87%;
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    .box_right_top{
      height: 40px;
      line-height: 40px;
      padding-left: 40px;
      background: #d7d7d7;
      border-bottom: 1px solid #d7d7d7;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-weight: bold;
    }
    .apply_box{
        margin: 10px 20px;
        .apply_list{
            border: 1px solid #f6f6f6;
            margin-bottom: 10px;
            .list-line{
                border-bottom: 1px solid #f6f6f6;
                height: 40px;
                line-height: 40px;
                .list-text{
                    width: 25%;
                    text-align: center;
                    background: #f5f5f5;
                    border-bottom: 1px solid #fff;
                    margin-right: 10px;
                }
                .list-number{
                    color: #CD9F49;
                }
                .list-width{
                  width: 70%;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
            }
        }
        .apply_barter{
            .barter_box{
                width: 200px;
                height: 57px;
                line-height: 57px;
                text-align:center;
                color: #fff;
                background: #CD9F49;
                margin-right: 20px;
            }
            .barter_Box{
                width: 200px;
                height: 57px;
                line-height: 57px;
                text-align:center;
                color: #CD9F49;
                border: 1px solid #CD9F49;
                margin-right: 20px;
            }
            .barter_cent{
                margin: 15px 0 0 25px;
                .el-form-item__content{
                  margin-left: 0px !important;
                }
                .barter_list{
                    margin: 15px 0;
                    display: flex;
                }
                .barter-input{
                    width: 200px;
                }
                .barter-goods{
                  margin: 10px 0;
                  .barter{
                    width: 220px;
                    text-align: center;
                    height: 34px;
                    line-height: 34px;
                    border: 1px solid #333;
                    margin: 10px 0;
                  }
                  .barter-color{
                    width: 42px;
                    height: 42px;
                    margin-right: 10px;
                    border: 1px solid #333;
                    background: crimson;
                  }
                  .barter-color1{
                    background: black;
                  }
                }
                .barter_textarea{
                    width: 493px;
                }
                .up_img{
                    margin: 15px 0;
                    .el-upload{
                        margin-right: 283px;
                    }
                    .el-upload--picture-card{
                      width: 82px;
                      height: 32px;
                      line-height: 32px;
                      border: none;
                    }
                    .up_hint{
                        color: red;
                        font-size: 14px;
                        margin: 10px 0;
                    }
                    .up_btn{
                        width: 100px;
                        height: 36px;
                        color: #333;
                        text-align: center;
                        border: 1px solid #999;
                        background: #f2f2f2;
                        margin-right: 18px;
                        border-radius: 5px;
                    }
                }
                
            }
        }
    }
    
   
  }
}
</style>